  <template>
 <orderTable :isB2B="true"/>
</template>

<script>
export default {
 
  
};
</script>

<style>
</style>